.costimize-bootstrap-table {
  width: 100%;
}
.costimize-bootstrap-table thead th {
  background-color: #07081b !important;
  color: #fff !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  /* text-align: center !important; */
  z-index: 0 !important;
}
.costimize-bootstrap-table .ant-table {
  background: transparent !important;
  color: #fff;
}

.costimize-bootstrap-table tbody tr td {
  /* border-bottom: 1px solid #999; */
  border: none;
  border-bottom: 1px solid #545454 !important;
}
.costimize-bootstrap-table .ant-table-cell-row-hover {
  background: transparent !important;
}
.ant-empty-description {
  color: #fff !important;
}
tr.ant-table-placeholder {
  background: transparent !important;
}
tr.ant-table-placeholder:hover {
  background: transparent !important;
}
li.ant-pagination-item {
  color: #fff;
}
.ant-pagination-item-active {
  color: #000 !important;
}
