@use "../../styles/mainsource";

.button-primary1 {
  background: linear-gradient(97.26deg, #f33a56 -13.78%, #6e039f 426.6%);
  border-radius: 5px;
  border: 0;
  font-weight: 600;
  // margin-top: -6px;
  font-size: 15px;
  width: 8.2rem;
  color: #fff;
}
.desktopnav {
  position: fixed;
  width: 100%;
}
nav.navbar {
  background-image: linear-gradient(150deg, #2c0e34 5%, #07081b 44%);
  // background: transparent;
  padding: 1rem 5rem;
  z-index: 2;
  border-bottom: 1px solid #5e4671;
}
.sdale-nav {
  padding: 1rem 5rem 1rem 1rem !important;
}
.sdelalogo {
  width: 12%;
  margin-right: 10px;
}
nav.nav-height {
  height: 4.3rem;
}
.navlogo {
  height: 1.5rem;
}
a.nav-link {
  display: flex;
  align-items: center;
  margin: 0rem 0.3rem;
  font-size: 0.875rem;
  font-weight: mainsource.$font-weight700;
  color: mainsource.$text-color-grey !important;
  cursor: pointer;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  top: 3rem;
  left: -6rem;
}

a.nav-link:active {
  color: red !important;
}
a.signup {
  font-weight: mainsource.$font-weight700;
  margin-left: 2rem;
}

.colorSecondary {
  color: #232323 !important;
}

// position: absolute;
// left: -60%;
// top: 3rem;
// width: fit-content;

.profile-icon {
  height: 4vh;
  width: 2vw;
}

.icon {
  height: inherit;
  width: inherit;
  border-radius: 5px;
}

// .desktopnav {
//   display: block;
// }

// .mobilenav {
//   display: none;
// }

.faRegBell {
  color: #1a3387 !important;
}
// .navbar-expand-lg .navbar-collapse {
//   margin-top: -40px;
// }

.navBarItem {
  color: #1c3892 !important;
}

// a.navBarItem:active {

// }
.navBarItemActive {
  background: linear-gradient(90.95deg, #f33a56 5.93%, #6e039f 131.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}
.dropdown-item:active span,
.dropdown-item:active,
.dropdown-item:active a {
  // background-color: #1a3387 !important;
  // color: white !important;
}

.button-primary.signin {
  padding: 0.5rem 2rem;
}

a .navBarItem:hover {
  background: linear-gradient(
    90.95deg,
    #f33a56 5.93%,
    #6e039f 131.13%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  transition: all 0.2s;
  scale: 1.2;
}

.nav-notification-bell {
  color: #fff;
  font-size: 27px;
}

.nav-dropdown {
  position: relative;

  font-size: 2.5rem;
  margin: -27px;
}
.nav-question-search {
  color: rgb(255 255 255 / 17%);
}
// .nav-question-search .ql-container.ql-snow {
//   border-radius: 12px;
//   border: 1px solid #424352;
//   background: #17193a;

// }
.nav-question-search .ql-container.ql-snow {
  border-radius: 12px;
  border: 1px solid #424352;
  background: #17193a;
  // width: 300px;
  // background-color: #f33a56;
}

.nav-question-search.expanded .ql-container.ql-snow {
  width: 600px;
  margin-left: -374px;
}

.nav-question-search .ql-editor {
  color: #c2c2c2; /* Change this to your desired text color */
  height: 43px;
}
.nav-question-search .ql-editor::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.nav-question-search .ql-editor::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 3px #D9E9FF;
  background-color: #D9E9FF; */
  background-color: #17193a;
  border-radius: 0.5rem;
}
.dropdown-menu{
  background-color: #17193a;
  --bs-dropdown-link-hover-bg:none;
  --bs-dropdown-link-active-bg: none;  
}
.dropdown-item {
  color: white !important;
  font-size: 0.8rem;
}
.nav-question-search .ql-editor::-webkit-scrollbar-thumb {
  background-color: #9a9a9a;
  border-radius: 10px;
  border: 2px solid #17193a;
}
/* Placeholder color */
.nav-question-search .ql-editor::before {
  color: rgba(255, 255, 255, 0.17);
}
.share-icon{
  width: 1.2rem;
  height:1.2rem;
  // font-size: 16px;
}
// @media only screen and (max-width: 768px) {
//   .desktopnav {
//     display: none !important;
//   }

//   .mobilenav {
//     display: block !important;
//   }
//   .responsive-navbar-nav {
//     padding-left: 2rem;
//   }
//   .navmobileitem a.nav-link {
//     position: relative;
//     right: 0em;
//     top: -0.5em;
//   }
//   .navmobileitem {
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//   }
//   nav.navbar {
//     background: mainsource.$color-white;
//     padding: 1rem 0rem !important;
//     // z-index: 1;
//     z-index: 2 !important;
//   }
//   .button-primary1 {
//     background-color: white !important;
//     border: none !important;
//     background: linear-gradient(100.38deg, #f33a56 0%, #6e039f 203.85%);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//     background-clip: text;
//     font-weight: 600;
//     // margin-top: -6px;
//     font-size: 15px;
//     width: 76px;
//   }
//   .navbar-expand-lg .navbar-collapse {
//     margin-top: 56px !important;
//     position: absolute !important;
//     background-color: white !important;
//     width: 40% !important;
//   }
//   .navbar-toggler {
//     border: none !important;
//     color: transparent !important;
//     margin-top: -20px !important;
//   }
//   .navlogo {
//     margin-top: 0.25rem;
//     // margin-left: 1.5rem;
//     height: 1.6rem !important;
//   }
//   .navbar-brand {
//     display: flex;
//     // justify-content: center;
//     // align-items: center;
//   }
//   .dropdown-menu[data-bs-popper] {
//     top: 56px;
//     left: -73px !important;
//     margin-top: var(--bs-dropdown-spacer);
//   }
//   footer .col {
//     flex: none !important;
//   }
//   .videoclass {
//     width: auto !important;
//     height: auto !important;
//   }
//   .highlight_findgigs {
//     position: absolute;
//     top: -20px;
//     left: 290px !important;
//   }
//   .FinDGigs h1 {
//     color: #1a3387;
//     font-size: 23px !important;
//   }
//   .FinDGigs_head_new {
//     margin-top: -40px !important;
//   }
//   .profile-icon {
//     width: 6vw !important;
//     object-fit: contain !important;
//   }
//   .navbar-toggler-icon {
//     display: inline-block;
//     width: 1em;
//     height: 1em;
//     position: absolute;
//     left: 2em;
//     top: 1.3rem;
//   }
// }
