/* start table */
tbody,
td,
tfoot,
th,
thead,
tr {
  border-width: thin;
  padding: 6px;
}

:root {
  --secondary-color: #d92aab;
  /* Default color */
}

table {
  width: 100% !important;
  border-collapse: collapse;
  /* Optional, for better spacing */
  border-radius: 10px;
}

h1 {
  font-size: 1rem;
}

h2 {
  font-size: 1rem;
}

h3 {
  font-size: 1rem;
}

/* end table */
.chat-main-row {
  margin: 0.5rem 0rem 1rem 0px;
  /* background: #17193a; */
  /* padding: 0px 24px; */
  border-radius: 4px;
  border: 1.5px solid rgba(0, 0, 0, 0.05) !important;
}

.chat-right-box {
  border-right: 1.5px solid rgba(0, 0, 0, 0.05);
  padding: 0px 1rem !important;
  background: #17193a;
  border-radius: 16px;
}

.chat-left-box {
  border-right: 1.5px solid rgba(0, 0, 0, 0.05);
  /* padding: 0px 1rem !important; */
  background: #17193a;
  border-radius: 16px;
  /* height: 42rem;
  overflow: auto; */
}

.right-bot-chat-body {
  padding: 1rem 0rem !important;
}

.chat-right-box-main {
  margin: 20px 0px;
  height: 100%;
  padding-bottom: 1rem;
}

/* .chat-left-avatar-details {
  height: 10%;
}
.chat-left-about {
  height: 15%;
}
.chat-left-askme {
  height: 13%;
}
.chat-left-prompts {
  height: 55%;
} */
.profile-picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #17193a;
}

.version-font-size {
  font-size: 0.7rem;
}

.profile-picture img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  /* Ensures the image fills the container without distortion */
}

.label-header {
  font-size: 19px;
  font-weight: 500;
  color: #232323;
  opacity: 88%;
  padding-left: 10px;
  border-left: 2px solid var(--secondary-color);
}

/* .ai-height {
  overflow-y: auto;
  max-height: 100px;
} */
.disable-ai-btn {
  cursor: not-allowed !important;
  background: #f39ddc !important;
}

.small-text {
  font-size: 12px;
  line-height: 1.7;
  font-weight: 400;
  /* padding-right: 0.8rem; */
}

.word-wrap {
  text-align: justify;
}

.colorSecondary {
  color: #fff !important;
}

.location,
.job-description,
.light-text {
  color: #fff;
  opacity: 50%;
}

.avatar-view-more {
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
}

.avatar-info-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.info-icon-text {
  font-size: 14px;
  margin-left: 0.4rem;
  font-weight: 400;
}

.avatar-info-img {
  width: 19px;
}

.bottom-hr-line {
  width: 100%;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.05);
}

.prompt-title-icon {
  /* color: #fff; */
  font-size: 19px;
}

.custom-width > .ant-form-item-row > .ant-form-item-label {
  width: 5rem;
}

.prompt-title-icon-active {
  color: var(--secondary-color) !important;
}

.button-primary:hover {
  background-color: #db2aac !important;
}

.button-primary-questions {
  margin: 4px 0px;
  width: 100% !important;
  color: #fff !important;
  background: #24264c !important;
  transition: ease 0.2s;
  text-align: left;
  border: none !important;
  box-shadow: 0px 11px 21px rgba(28, 56, 146, 0.03);
  padding: 0;
  border-radius: 10px;
}

.button-primary-questions:hover {
  box-shadow: 0px 39px 116px rgba(28, 56, 146, 0.14) !important;
  transition: all 0.25s;
}

.aivolvex-bot-tab > .ant-tabs-nav > .ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-library-logo {
  width: 18px;
}

.btn-prompts-content {
  font-size: 12px;
  font-weight: 400;
  padding: 0.5rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  text-align: left;
  width: 100%;
}

.ai-token-icon {
  margin-top: 3px;
}

.btn-prompts-token {
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2f325a;
  padding: 0 0.5rem;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.prompt-tap-title {
  color: var(--secondary-color) !important;
  font-size: 18px;
  font-weight: 500;
  margin: 0.7rem 0;
}

.prompts-history-icon {
  font-size: 21px;
  color: #fff;
  margin: 0.7rem 0.5rem;
}

/* input box */
.input-container {
  display: flex;
  margin-top: 20px;
  padding: 0rem 1rem;
  /* box-shadow: 0px 39px 116px #f0f0f0 !important; */
}

.input-container .input-container-textarea {
  color: #fff !important;
  background: #24264c;
  flex: 1 1;
  padding: 10px;
  border: 1px solid #24264c;
  border-radius: 8px 0 0 8px;
  width: 0px;
}

.chat-title {
  font-size: 18px;
  /* text-align: center; */
  font-weight: 400;
  color: #fff;
}

.promp-active {
  color: #db2aac;
}

.text-right {
  text-align: right !important;
}

.input-container button {
  padding: 0px 12px;
  border: none;
  /* background-color: #007bff; */
  color: #fff;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  font-size: 22px;
  background: #db2aac;
}

.chat-send-window {
  background-color: #17193a;
  border-radius: 5px;
  padding: 0px 10px;
  width: 100%;
  color: #fff;
}

.chat-avatar-icon-color {
  background: #fff !important;
  color: #07081b !important;
}

.chat-ans-window {
  background-color: #24264c;
  border-radius: 5px;
  padding: 0px 10px;
  width: 100%;
  color: #fff;
}

.chat-message {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.input-container-icon {
  margin-top: -4px;
}

.profile-token-icon {
  width: 32px;
  margin-right: 8px;
}

.chat-avator-img {
  width: 32px;
  height: 32px;
  margin-right: 4px;
  border-radius: 50%;
}

.sidebar-footer {
  width: 78%;
  background: #17193a;
  border: 1px solid #424352;
  padding: 15px;
  margin: 0px 24px;
  color: #fff;
  border-radius: 11px;
  font-size: 14px;
  font-weight: 400;
}

.sidebar-footer-body {
  width: 18%;
  position: fixed;
  bottom: 0;
  font-size: 11px;
  font-weight: 600;
}

.sidebar-footer-version {
  color: #cd28a2;
  font-size: 12px;
  text-align: center;
  margin: 10px 0px;
}

.footer-chat-time {
  font-size: 10px;
  font-weight: 500;
  color: #3e3f5b;
}

.right-bot-head {
  margin: 10px 0px;
  text-align: center;
  border-bottom: 1px solid #5f6077;
}

.feedback-body {
  text-align: right;
  width: 17%;
}

.time-question-body {
  text-align: left;
}

.feedback-icon {
  margin: 0px 3px;
  cursor: pointer;
  color: #4d4f6e;
  font-size: 21px;
}

.chat-body {
  margin: 0px 4rem;
}

.responce-pre {
  word-wrap: break-word;
  overflow: auto;
  white-space: pre-wrap;
  font-family: unset;
  font-size: 0.875rem;
  line-height: 1.7;
  font-weight: 400;
  margin: 0px;
}

.responce-pre::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.responce-pre::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 3px #D9E9FF;
  background-color: #D9E9FF; */
  background-color: #fae8f6;
  border-radius: 0.5rem;
}

.responce-pre::-webkit-scrollbar-thumb {
  background-color: #9a9a9a;
  border-radius: 10px;
  border: 2px solid #fae8f6;
}

.chat-home-icon {
  font-size: 21px;
  cursor: pointer;
  color: #fff;
}

.promp-logo {
  color: #fff;
  font-size: 25px;
}

/* temp */
span.ant-avatar.ant-avatar-circle.css-dev-only-do-not-override-txh9fw {
  margin-left: 6px;
}

.side-scroll-container-shadow {
  position: relative;
  /* background-color: #ffffff; */
}

.side-scroll-container-shadow::after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 7rem;
  /* Adjust the height of the shadow as needed */
  background: linear-gradient(to top, rgb(23 25 58), transparent);
}

/* scroll */
/* WebKit (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* IE/Edge */
.custom-scrollbar {
  -ms-overflow-style: none;
  /* Hide the scrollbar in IE/Edge */
}

.custom-scrollbar::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar in WebKit (Chrome, Safari) */
}

/* Track style (background) */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #000000;
  -webkit-box-shadow: inset 0 0 3px #d9e9ff;
  /* background-color: #D9E9FF; */
}

/* Handle style (thumb) */
.custom-scrollbar::-webkit-scrollbar-thumb {
  /* background: #0c0000;
  border-radius: 4px; */

  background-color: #1b5f9d;
  border-radius: 10px;
  border: 2px solid #ffff;
}

/* Control the height of the scrollbar */
.custom-scrollbar {
  max-height: 150px;
  /* Set the maximum height for the custom scrollbar */
  overflow-y: auto;
  /* Enable vertical scrolling when content overflows */
}

.responce-pre > html > body {
  background: transparent !important;
}

.aivolvex-bot-tab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  background: transparent;
}

.aivolvex-bot-tab .ant-tabs-tab-btn {
  color: #848484 !important;
  padding: 0rem 1rem;
}

.aivolvex-bot-tab .ant-tabs-tab-btn {
  color: #848484 !important;
  padding: 0rem 1rem;
  font-weight: 400;
  font-size: 15px;
}

.aivolvex-bot-tab .ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #db2aac !important;
  font-weight: 400;
  font-size: 15px;
}

.bot-kendra-pdf {
  text-align: right;
  cursor: pointer;
}

/* loader */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #e55cc1;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* .ant-rate-star-zero {
  color: #fff !important;
} */
.ant-rate-star-zero .ant-rate-star-second .anticon-star {
  color: #757474;
}

/* .ant-rate-star-full{

} */

/* .ant-rate-star-zero {
  color: #fff !important;
} */

.social-media-share {
  margin: 2px;
}

/* start scroll */
.side-scroll-container {
  width: 100%;
  height: 7rem;
  position: relative;
  overflow: auto;
  padding-right: 0rem;
  white-space: break-spaces;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset; */
}

.side-scroll-container-aivolvex {
  width: 100%;
  height: 3rem;
  position: relative;
  overflow: auto;
  padding-right: 0rem;
  white-space: break-spaces;
}

/* .side-scroll-container::after {
  content: "";
  position: absolute;
  bottom: -10px; 
  left: 0;
  width: 100%;
  height: 10px; 
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.1)
  ); 
  z-index: -1;
} */

.side-scroll-container::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.side-scroll-container::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 3px #D9E9FF;
  background-color: #D9E9FF; */
  background-color: #17193a;
  border-radius: 0.5rem;
}

.side-scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.19);
  border-radius: 10px;
  border: 2px solid #17193a;
}

.side-scroll-container-prompts {
  width: 100%;
  height: 10rem;
  position: relative;
  overflow: auto;
  padding-right: 1rem;
  white-space: break-spaces;
}

.side-scroll-container-prompts::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.side-scroll-container-prompts::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 3px #D9E9FF;
  background-color: #D9E9FF; */
  background-color: #17193a;
  border-radius: 0.5rem;
}

.side-scroll-container-prompts::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.19);
  border-radius: 10px;
  border: 2px solid #17193a;
}

.side-scroll-container-chat {
  width: 100%;
  height: 25rem;
  position: relative;
  overflow: hidden;
  padding-right: 0rem;
  white-space: break-spaces;
  padding-bottom: 0rem;
}

.side-scroll-content {
  width: calc(97% + 17px);
  height: 100%;
  overflow-y: scroll;
  /* padding-right: 1.5rem; */
  line-height: 1.5;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.side-scroll-content::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.side-scroll-content::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 3px #D9E9FF;
  background-color: #D9E9FF; */
  background-color: #17193a;
  border-radius: 0.5rem;
}

.side-scroll-content::-webkit-scrollbar-thumb {
  background-color: #2d2e4d;
  border-radius: 10px;
  border: 2px solid #17193a;
}

.scroll-icon {
  cursor: pointer;
  font-size: 1.5rem;
  color: #fff;
  position: absolute;
  right: 0%;
  transform: translateX(-50%);
  z-index: 1;
}

.scroll-icon-up {
  top: 0px;
}

.scroll-icon-down {
  bottom: 2px;
}

.scroll-icon-down-chat-scroll {
  bottom: 47px;
  right: -8px;
  background: #17193a;
}

.help-key-icon {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

/* end scroll */
/* icon pagination */
.paginaction-icon {
  font-size: 9px;
  cursor: pointer;
  margin-bottom: 2px;
}

.paginaction-icon-disable {
  font-size: 9px;
  margin-bottom: 2px;
  color: #b2b2b2;
  cursor: not-allowed;
}

.page-view {
  display: block;
}

.page-hide {
  display: none;
}

/* icon pagination */

/* source table */

.source-bootstrap-table {
  width: 100%;
}

.source-bootstrap-table thead th {
  background-color: #07081b !important;
  color: #fff !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  /* text-align: center !important; */
  z-index: 0 !important;
}

.source-bootstrap-table .ant-table {
  background: transparent !important;
  color: #000;
}

.source-bootstrap-table tbody tr td {
  /* border-bottom: 1px solid #999; */
  border: none;
  border-bottom: 1px solid #545454 !important;
}

.source-bootstrap-table .ant-table-cell-row-hover {
  background: transparent !important;
}

/* end source table */

.prompt-tap-background {
  background: #24264d;
  color: #fff;
  margin: 5px;
  padding: 2rem;
  border-radius: 10px;
}

.prompt-tap-content {
  margin-bottom: 0.7rem;
}

.user-feedback-comment-title {
  color: #db2aac;
  text-decoration: underline;
}

.chat-empty-background {
  width: 100%;
}

/* .suggestions-list{
  background-color: wheat;
  color: #000;
} */
.btn-chat-token {
  font-size: 17px;
  font-weight: 400;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2f325a;
  padding: 0 0.5rem;
  color: #fff;
}

.token-chat-logo {
  width: 27px;
  margin-left: 0.2rem;
}

.chat-ans-box-token {
  font-size: 17px;
  font-weight: 400;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  color: #fff;
}

.message-taken-logo {
  width: 15px;
  margin-left: 0.2rem;
}

.chat-messge-ans {
  background: #24264c;
}

/* react quill */
.chat-react-quill {
  padding: 0px !important;
}

.chat-react-quill .ql-container {
  margin: 0px;
  width: 100%;
  border-radius: 8px 0 0 8px;
  padding: 9px 0px;
}

.chat-react-quill .ql-container .ql-editor {
  white-space: pre-wrap;
  /* Preserve whitespace and wrap lines */
  overflow: hidden !important;
}

.chat-react-quill .ql-container .ql-editor::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.chat-react-quill .ql-container .ql-editor::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 3px #D9E9FF;
  background-color: #D9E9FF; */
  background-color: #17193a;
  border-radius: 0.5rem;
}

.chat-react-quill .ql-container .ql-editor::-webkit-scrollbar-thumb {
  background-color: #2d2e4d;
  border-radius: 10px;
  border: 2px solid #17193a;
}

/*end react quill */

/* tool list */
.tool-list {
  width: 30%;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
  margin-left: 3%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #424352;
  border-radius: 4px;
  background-color: #17193a;
  color: #fff;
  margin-top: -3%;
}

.tool-list-item {
  padding: 8px 0 8px 10px;
  border-bottom: 1px solid #848484;
  cursor: pointer;
}

.tool-list-item:last-child {
  border-bottom: none;
}

.custom-file-upload {
  display: inline-block;
  background-color: #2f325a;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
}

.upload-icon {
  font-size: 21px;
  display: inline-block;
  background-color: #2f325a;
  color: white;
  padding: 0px 16px;
}

.con-share-icon {
  font-size: 31px;
  margin-right: 1rem;
  color: #fff;
}

.con-share-icon-left {
  font-size: 28px;
  margin-left: 1rem;
  color: #fff;
}

.header-inner-padding {
  padding: 0px 1.5rem;
}

.text-left-ai {
  text-align: left !important;
}

.alert-content-view {
  color: #9fa1b4;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
}

.edit-input-filed {
  width: 100% !important;
}

.lib-history {
  background: linear-gradient(to top, rgb(23 25 58), transparent);
  background: #24264c !important;
  padding: 5px;
  border: none;
  width: 97%;
  /* height: 48px; */
  border-radius: 10px;
}

.history-search {
  padding-top: 10px;
  padding-bottom: 10px;
}

.lib-search-icon {
  color: #848484;
  font-size: 15px;
  padding: auto;
}

/* end tool list */

/* .bg-img-center {
  display: flex;
  justify-content: center;
  align-items: center;
} */
@media (min-width: 2561px) {
  .side-scroll-container-chat {
    height: 57rem !important;
  }

  .chat-empty-background {
    height: 58rem;
  }
}

@media only screen and (max-width: 2560px) and (min-width: 2305px) {
  .side-scroll-container-chat {
    height: 57rem !important;
  }

  .chat-empty-background {
    height: 58rem;
  }
}

@media only screen and (max-width: 2304px) and (min-width: 2161px) {
  .side-scroll-container-chat {
    height: 62rem !important;
  }

  .chat-empty-background {
    height: 58rem;
  }
}

@media only screen and (max-width: 2160px) and (min-width: 1922px) {
  .side-scroll-container-chat {
    height: 44rem !important;
  }

  .chat-empty-background {
    height: 46rem;
  }
}

@media only screen and (max-width: 1921px) and (min-width: 1681px) {
  .side-scroll-container-chat {
    height: 44rem !important;
  }

  .chat-empty-background {
    height: 46rem;
  }
}

@media only screen and (max-width: 1680px) and (min-width: 1601px) {
  .side-scroll-container-chat {
    height: 33rem !important;
  }

  .chat-empty-background {
    height: 35rem;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1471px) {
  .side-scroll-container-chat {
    height: 28rem !important;
  }

  .chat-empty-background {
    height: 29rem;
  }
}

@media only screen and (max-width: 1470px) and (min-width: 1441px) {
  .side-scroll-container-chat {
    height: 27rem !important;
  }

  .chat-empty-background {
    height: 29rem;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {
  .side-scroll-container-chat {
    height: 24rem !important;
  }

  .chat-empty-background {
    height: 27rem;
  }
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  .side-scroll-container-chat {
    height: 22rem !important;
  }

  .chat-empty-background {
    height: 24rem;
  }

  .feedback-body {
    text-align: right;
    width: 20%;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1081px) {
  .feedback-body {
    text-align: right;
    width: 20%;
  }
}

@media only screen and (max-width: 1080px) and (min-width: 1025px) {
  .feedback-body {
    text-align: right;
    width: 10%;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .chat-body {
    margin: 0px 3rem;
  }

  .feedback-body {
    text-align: right;
    width: 10%;
  }
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .chat-body {
    margin: 0px 0rem;
  }

  .side-scroll-content {
    width: calc(96% + 17px);
  }
  .lib-history {
    width: 91%;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .chat-body {
    margin: 0px 0rem;
  }

  .side-scroll-content {
    width: calc(96% + 17px);
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .chat-body {
    margin: 0px 0rem;
  }

  .side-scroll-content {
    width: calc(95% + 17px);
  }
}

@media (max-width: 320px) {
  .chat-body {
    margin: 0px 0rem;
  }

  .side-scroll-content {
    width: calc(95% + 17px);
  }
}
