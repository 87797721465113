.mention-input-container {
  position: relative;
  width: 14rem;
}

.mention-input {
  padding: 5px;
  border-radius: 9px;
  margin-left: 8%;
  height: 34px;
  margin-top: 3%;
  border: 1px solid #424352;
  color: #fff;
  background-color: #17193a;
  font-size: 16px;
  resize: vertical;
}
.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
  margin-left: -154%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #424352;
  border-radius: 4px;
  background-color: #17193a;
  color: #fff;
  margin-top: -3%;
}

.suggestions-list-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
  margin-left: -167%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #424352;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #17193a;
  color: #fff;
}

.suggestions-list-list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.suggestions-list-list::-webkit-scrollbar-track {
  background-color: #17193a;
  border-radius: 0.5rem;
}
.suggestions-list-list::-webkit-scrollbar-thumb {
  background-color: #9a9a9a;
  border-radius: 10px;
  border: 2px solid #17193a;
}

.suggestions-list-list li {
  padding: 8px 12px;
  cursor: pointer;
}

.suggestions-list-list li.active {
  background-color: #f0f0f0;
}
.suggestions-list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.suggestions-list::-webkit-scrollbar-track {
  background-color: #17193a;
  border-radius: 0.5rem;
}
.suggestions-list::-webkit-scrollbar-thumb {
  background-color: #9a9a9a;
  border-radius: 10px;
  border: 2px solid #17193a;
}

.suggestions-list li {
  padding: 8px 12px;
  cursor: pointer;
}

.suggestions-list li.active {
  background-color: #f0f0f0;
}
.search-suggestion {
  color: #db2aac important;
}

.mentions-container {
  position: absolute;
  top: 38px;
  left: 10px;
  display: flex;
  flex-wrap: wrap;
  z-index: 2;
  margin-top: -12%;
  margin-left: 46%;
  background-color: transparent;
  color: #848484;
}

.mention {
  display: inline-flex;
  align-items: center;
  background-color: #848484;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 6px;
  margin-left: 72px;
}

.delete-button {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  font-size: 14px;
  margin-left: 4px;
  height: 13px;
}

.delete-button:hover {
  color: #d9534f;
}
.mention-selected {
  color: #db2aac !important;
  font-weight: bold;
}

.mention-normal {
  color: white;
}
.username {
  color: white;
}

.suggested {
  color: #db2aac !important;
  margin-left: -47%;
}

.ql-editor {
  overflow-y: hidden !important;
  padding: 0px 10px !important;
}
.placeholder-style::placeholder {
  color: gray !important;
}

.nav-question-search .ql-snow * {
  box-sizing: border-box;

  display: flex !important;
}

.nav-question-search .ql-container.ql-snow {
  border: 1px solid #ccc;
  padding: 5px;
  width: 254px;
  border-radius: 9px;
  margin-left: -12%;
  height: 34px;
  margin-top: 3%;
  border: 1px solid #424352;
  color: #fff;
  background-color: #17193a;
  font-size: 16px;
  resize: vertical;
}

p span {
  color: #db2aac !important;
  font-style: italic;
}
.ql-container.ql-snow {
  border: 1px solid #3e405b !important;
}

.placeholder-text {
  position: absolute;
  color: gray;
  pointer-events: none;
  padding: 10px;
}

.suggestions-list-item {
  padding: 8px 0;
  border-bottom: 1px solid #848484;
  cursor: pointer;
}

.suggestions-list-item:last-child {
  border-bottom: none;
}
.ql-editor {
  overflow: hidden !important;
}
.nav-question-search .ql-editor p {
  white-space: nowrap !important;
}
.avatar_icon_image {
  width: 10%;
  border-radius: 50%;
  margin-right: 0.5rem;
}
