.signInCarousel1Image,
.signInCarousel3Image,
.signInCarousel2Image {
  // height: 100% !important;
  height: 673px !important;
  width: 100% !important;
}

.signInCarousel2Image {
  width: 32rem;
}

.signInCarousel3Image {
  width: 25rem;
}

.splide__pagination__page {
  background: #f6ecec;
  border: 0;
  // border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 5rem;
}

.splide__pagination__page {
  background: #f6ecec;
  border: 0;
  /* border-radius: 50%; */
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 5rem;
}

.splide__pagination__page.is-active {
  background: linear-gradient(90deg, #f33a56 -1.19%, #6e039f 164.88%);
  transform: scale(1.4);
  z-index: 1;
}

@media only screen and (max-width: 2560px) and (min-width: 2305px) {
  .signInCarousel1Image,
  .signInCarousel3Image,
  .signInCarousel2Image {
    width: 80rem !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 2304px) and (min-width: 2161px) {
  .signInCarousel1Image,
  .signInCarousel3Image,
  .signInCarousel2Image {
    width: 72rem !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 2160px) and (min-width: 1922px) {
  .signInCarousel1Image,
  .signInCarousel3Image,
  .signInCarousel2Image {
    width: 66rem !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 1921px) and (min-width: 1681px) {
  .signInCarousel1Image,
  .signInCarousel3Image,
  .signInCarousel2Image {
    width: 59rem !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 1680px) and (min-width: 1601px) {
  .signInCarousel1Image,
  .signInCarousel3Image,
  .signInCarousel2Image {
    width: 50rem !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1471px) {
  .signInCarousel1Image,
  .signInCarousel3Image,
  .signInCarousel2Image {
    width: 50rem !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 1470px) and (min-width: 1441px) {
  .signInCarousel1Image,
  .signInCarousel3Image,
  .signInCarousel2Image {
    width: 45rem !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {
  .signInCarousel1Image,
  .signInCarousel3Image,
  .signInCarousel2Image {
    // height: 1100px !important;
    width: 38rem !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  .signInCarousel1Image,
  .signInCarousel3Image,
  .signInCarousel2Image {
    // height: 1100px !important;
    width: 38rem !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1081px) {
  .signInCarousel1Image,
  .signInCarousel3Image,
  .signInCarousel2Image {
    // height: 1100px !important;
    width: 36rem !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 1080px) and (min-width: 1025px) {
  .signInCarousel1Image,
  .signInCarousel3Image,
  .signInCarousel2Image {
    width: 33rem !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .signInCarousel1Image,
  .signInCarousel3Image,
  .signInCarousel2Image {
    // height: 1100px !important;
    width: 32rem !important;
    height: 100vh !important;
  }
  .siginbanner-1 {
    font-size: 1.2rem !important;
  }
}
