@import url("https://fonts.cdnfonts.com/css/axiforma");

.Dashboard {
  display: grid;
  grid-template-columns: 0.7fr 3fr;
  height: 89vh;
}
.DashboardContent {
  height: 89vh;
  overflow-y: scroll;
}
// .DashboardContent::-webkit-scrollbar {
//   display: none;
// }
.DashboardContent {
  padding: 3rem;
}
.Dashboard_head {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
}
.Dashboard_graph {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}
.Dashboard_head_box_shadow {
  box-shadow: 0px 39px 116px rgba(28, 56, 146, 0.14) !important;
}
.Dashboard_head_box {
  background-color: #17193b;
  // padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  padding: 0.25rem 1rem 0.25rem 1rem;

  & h2 {
    color: #fff;
    font-size: 20px;
  }
  & p {
    color: #fff;
    // color: rgb(172, 172, 172);
  }
  & img {
    height: 30px;
    width: 30px;
  }
}
.Dashboard_body {
  margin-top: 3rem;
  display: grid;
  // grid-template-columns: 2.5fr 1fr !important;
  grid-gap: 1rem;
}

.Dashboard_graph_box {
  background-color: #17193a;
  // padding: 1rem 1rem;
  // display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
}
// .apexcharts-text tspan {
//   font-family: revert;
// }

.dashboard-datepicker {
  height: 75%;
}
.Dashboard_count_graph {
  background-color: #171a3b;
  /* display: flex; */
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  border-radius: 10px;
  padding: 0.25rem 1rem 0.25rem 1rem;
  //   margin-top: 1rem;
}

.apexcharts-menu-icon {
  display: none;
}
.chart-dropdown-icon-text {
  font-size: 21px;
}
.chart-dropdown {
  margin-left: 95%;
  font-size: 21px;
  //  float:right !important;
}
// .chart-dropdown-btn{
//   background-color: #fff;
//   color:#0000;
//   border: none;
// }
